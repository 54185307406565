body {
    font-size: 1.2rem;
}
.app {
}

/*
    ******
    NAVBAR
    ******
*/
.navbar {
    width: 100%;
}
.navbar a.navbar-main-link {
    color: #ffb183;
    white-space: nowrap;
    margin-right: 1rem;
    text-decoration: none;
}
.navbar a:active.navbar-main-link {
    color: #ffb183;
}
.navbar a:visited.navbar-main-link {
    color: #ffb183;
}
.navbar a:hover.navbar-main-link {
    color: #f58747;
}
.navbar-custom a {
    color: #ffb183;
}
.navbar-custom .navbar-nav a.nav-link {
    color: #ffb183;
}
.navbar-custom .navbar-nav a:active.nav-link {
    color: #ffb183;
}
.navbar-custom .navbar-nav a:visited.nav-link {
    color: #ffb183;
}
.navbar-custom .navbar-nav a:hover.nav-link {
    color: #f58747;
}
.nav-social-icon {
    padding: 0 1rem;
}
.navbar-custom-icon {
    font-size: 1.8rem;
    display: inline-block;
}
.navbar-custom-icon.facebook {
    margin-top: -1px;
}
.navbar-custom-icon.mail {
    font-size: 2rem;
    margin-top: -2px;
}
.navbar a {
    color: white;
}
.navbar-toggler-custom {
    color: white;
}
.navbar-items-wrapper {
    padding-left: 22px;
    padding-right: 14px;
}
.nav-item {
    text-align: left;
    white-space: nowrap;
}
.navbar-toggle-wrapper {
    display: flex;
    justify-content: flex-end;
}
.navbar-custom {
    background-color: #2a678f !important;
    font-size: 18px !important;
}
.custom-nav-item {
    float: left;
    clear: both;
}
.custom-header {
    margin-bottom: 0;
}
.site-header-wrapper {
    width: 100%;
    overflow: hidden;
}
@media screen and (min-width: 768px) {
    .site-header-wrapper {
    }
    img.site-header-image {
        margin-top: 15px;
    }
}
@media screen and (max-width: 768px) {
    .site-header-wrapper {
    }
    img.site-header-image {
        margin-top: -5px;
    }
}
@media screen and (min-width: 992px) {
    .site-header-wrapper {
    }
    img.site-header-image {
        margin-top: -14px;
    }
}
@media screen and (min-width: 1200px) {
    .site-header-wrapper {
    }
    img.site-header-image {
        margin-top: -30px;
    }
}
@media screen and (min-width: 1400px) {
    .site-header-wrapper {
    }
    img.site-header-image {
        margin-top: -50px;
    }
}
@media screen and (min-width: 1600px) {
    img.site-header-image {
        margin-top: -65px;
    }
}
@media screen and (min-width: 1800px) {
    img.site-header-image {
        margin-top: -80px;
    }
}
.otherNavItems-normal {
    display: flex;
}
.otherNavItems-dropdown {
    display: none;
}
@media screen and (min-width: 768px) and (max-width: 1200px) {
    .otherNavItems-normal {
        display: none;
    }
    .otherNavItems-dropdown {
        display: flex;
    }
}
.navbar-collapse-custom {
    flex-grow: 0;
}
.navbar-collapse-custom > .navbar-nav {
    padding-right: 0;
}
.navbar-collapse-custom > .navbar-nav:last-child {
    padding-right: 0;
}
.nav-item {
    margin-left: .25rem;
}
.navbar-divider {
    opacity: 1;
    background-color: #ffb18388;
    margin: .75rem .5rem .75rem 0
}
@media screen and (max-width: 768px) {
    .nav-item.nav-social {
        margin-right: 1rem;
    }
    .nav-item.nav-social:last-child {
        margin-right: 0;
    }
}
@media screen and (min-width: 768px) {
    .navbar-divider {
        display: none;
    }
}
.navbar-nav-links {
    flex-direction: row;
    flex-wrap: nowrap;
}
.dropdown-menu-custom {
    background-color: #1a5e84;
}
/* .nav-1 {
    background-color: red !important;
}
.nav-2 {
    background-color: whitesmoke !important;
}
.nav-3 {
    background-color: blue !important;
} */

/*
    ***********
    SITE HEADER
    ***********
*/
.site-header-image {
    margin-top: 0;
    pointer-events: none;
    width: 100%;
}
@media screen and (min-width: 768px) {
    /* .navbar-expand-md .navbar-collapse-justify-right {
        display: block !important;
    }
    .navbar-nav-custom {
        justify-content: flex-end !important;
        padding-top: 6px;
        flex-flow: wrap;
    } */
}
@media screen and (max-width: 768px) {
    .site-header-image {
        margin-top: 25px !important;
    }
}
@media screen and (max-width: 992px) {
    img.site-header-image {
        margin-top: 30px;
    }
}

@media screen and (min-width: 992px) {
    span.policy-item {
        text-align: left;
    }
}
@media screen and (min-width: 1200px) {
}

/*
    *******
    GENERAL
    *******
*/
.section-title {
    display: block;
    font-size: 2.5rem;
    font-weight: 700;
    color: #4d4d4d;
    padding: 20px 0 8px;
    position: relative;
    text-align: center;
}
.section-title::before {
    content: "";
    position: absolute;
    left: 49.5%;
    bottom: 12px;
    height: 14px;
    transform: skew(-12deg) translateX(-50%);
    background: #ff8101dd;
    z-index: -1;
    width: 220px;
}

/*
    *******
    MESSAGE
    *******
*/
.message-container {
    position: relative;
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 0;
    padding: 0;
    overflow:hidden;
}
.message-text {
    padding: 0 20px 20px;
    width: 100%;
    text-align: center;
    line-height: 2.5rem;
    position:relative;
    top:0;
    left:50%;
    transform: translate(-50%, -10px);
    z-index: 100;
}
.message-text > .message-title {
    margin-bottom: 4rem;
}
.message-text > .message-title::before {
    bottom: 2px;
}
.message-img-container {
    position: absolute;
    text-align: center;
    width: 100%;
    top: 112px;
}
.message-img-container::after {
    position: absolute;
    content: '';
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255,255,255,.85);
}
.message-img-container img.message-img {
    height: 180px;
}
@media screen and (min-width: 768px) {
    .message-text {
        padding-bottom: 60px;
    }
}
@media screen and (min-width: 992px) {
    .message-text {
        padding-bottom: 50px;
    }
    .message-img-container {
        top: 105px;
    }
}
@media screen and (min-width: 1200px) {
    .message-container {
        margin-top: 4rem;
        margin-bottom: 0;
    }
}

/*
    *****
    GOALS
    *****
*/
.policy-container {
    margin-bottom: 50px;
}
.policy-title {
    color: #4d4d4d;
    text-align: center;
    font-size: 2.5rem;
    font-weight: 700;
    padding: 30px 0 8px;
    position: relative;
    margin-bottom: 25px;
}
.policy-title::before {
    content: "";
    position: absolute;
    left: 49.6%;
    bottom: 10px;
    width: 108px;
    height: 14px;
    transform: skew(-12deg) translateX(-50%);
    background: #ff8101dd;
    z-index: -1;
}
.policy-wrapper {
    margin-bottom: 20px;
    margin-left: 68px;
    margin-right: 12px;
}

.policy-item-wrapper {
    /* width: fit-content; */
    margin-left: auto;
    margin-right: auto;
    padding-left: 45px;
    text-indent: -45px;
    
}
.policy-item {
    display: inline-block;
    padding-right: 30px;
    margin-bottom: 24px;
    overflow: auto;
    font-weight: 500;
    overflow: visible;
}
img.policy-tick {
    height: 35px;
    top: 0;
    display: inline-block;
    padding-right: 10px;
}

/*
    *******
    PROFILE
    *******
*/
.profile-title {
    padding-top: 12px;
    margin-bottom: 30px;
}
.profile-title::before {
    width: 260px;
}
.profile-text {
    text-align: left;
    margin-top: 24px;
}
.profile-text p {
    margin-bottom: 1.6rem;
}
.profile-divider {
    height: 1px;
    padding: 0;
    margin: -8px auto 18px;
    border-bottom: #f5874788 12px solid;
    width: 50px;
    transform: skewX(-15deg);
}
.profile-banner-wrapper {
    width: 100%;
    margin: 0;
    position: relative;
    overflow: hidden;
}
img.profile-banner {
    width: 100%;
    z-index: 10;
    position: relative;
}
.profile-container {
    z-index: 100;
    margin-top: -88px;
    margin-bottom: 16px;
    background-color: #ffffff88;
    position: relative;
}
@media screen and (min-width: 768px) {
    .profile-banner-wrapper {
        height: 300px;
    }
    img.profile-banner {
        left: -5%;
        width: 105%
    }
    .profile-container {
        margin-top: -88px;
        background-color: #eaeaea99;
        padding-left: 16px;
        padding-right: 16px;
    }
    .profile-title {
        padding: 12px 0 8px;
        margin-bottom: 35px;
    }
    .profile-text {
        margin-left: 20px;
        margin-right: 20px;
        padding-bottom: 1rem;
    }
}
@media screen and (max-width: 768px) {
    
    .profile-title {
        text-shadow: 0px 0px 5px #ffffff;
    }
}
@media screen and (min-width: 992px) {
    img.profile-banner {
        left: -8%;
        width: 108%
    }
    .profile-container {
        margin-bottom: 30px;
    }
}
@media screen and (min-width: 1200px) {
    
}

.img-yoko-speech {
    width: 100%;
    width: -moz-available;
    width: -webkit-fill-available;
    width: stretch;
}

.home-marker {
    width: 100%;
    height: 0;
}

span.no-break {
    white-space: nowrap;
}

.personality-wrapper {
    margin-bottom: 1rem;;
}

.personality-title {
    color: #4d4d4d;
    text-align: center;
    font-size: 2.5rem;
    font-weight: 700;
    padding: 20px 0 8px;
    position: relative;
    margin-bottom: 25px;
}

.personality-title::before {
    content: "";
    position: absolute;
    left: 49.5%;
    bottom: 10px;
    width: 220px;
    height: 14px;
    transform: skew(-12deg) translateX(-50%);
    background: #ff8101dd;
    z-index: -1;
}
.yoko-image-wrapper {
    width: fit-content;
    border: #444 1px solid;
    margin-left: auto;
    margin-right: auto;
    border-radius: 4px;
    overflow: hidden;
}
img.yoko-to-usagi {
    height: 250px;
    
}
.personal-like {
    text-align: center;
    font-size: 1.5rem;
    font-weight: 500;
    padding-bottom: 2rem;
}
.likes-left {
    margin-left: 0;
}
.likes-right {
    margin-right: .85rem;
}
.like-heart {
    height: 2rem;
}
@media screen and (min-width: 768px) {
    .likes {
        padding-top: 1rem;
    }
    .heart-top {
        margin-bottom: 1.2rem;
        height: 90px;
    }
    .personality-wrapper {
        margin-bottom: 4rem;;
    }
}
@media screen and (max-width: 768px) {
    .yoko-image-wrapper {
        margin-bottom: 2rem;
    }
    .personal-like {
        padding-bottom: 1.2rem;
    }
}

.contact-wrapper {
    margin-bottom: 0;
}

.contact-col {
    margin-bottom: 0;
}
.contact-col.contact-details {
    margin-bottom: 2rem;
}

.contact-title {
    color: #4d4d4d;
    text-align: center;
    font-size: 2.5rem;
    font-weight: 700;
    padding: 20px 0 8px;
    position: relative;
    margin-bottom: 25px;
}

.contact-title::before {
    content: "";
    position: absolute;
    left: 49.5%;
    bottom: 10px;
    width: 220px;
    height: 14px;
    transform: skew(-12deg) translateX(-50%);
    background: #ff8101dd;
    z-index: -1;
}

.contact-row {
    margin-bottom: 4px;
}

.contact-row.emphasis {
    font-weight: 500;
}

.contact-row.mb-extra {
    margin-bottom: 16px;
}

.contact-details {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
}
.contact-icon {
    display: inline-block;
    width: 2rem;
}
@media screen and (min-width: 992px) {
    .contact-col.contact-details {
        margin-bottom: 0;
    }
}
@media screen and (min-width: 1400px) {
    .contact-details {
        margin-left: 0;
    }
}

.footer-wrapper {
    width: 100%;
    background-color: #eaeaea;
    padding: 36px 0 36px;
    border-top: 8px #f58747 solid;
}
.footer-content {
    display:flex;
    flex-flow: wrap;
    margin-left: auto;
    margin-right: auto;
}
.footer-content .footer-1 {
    width: 100%;
    display: inline-block;
    
}
.footer-content .footer-2 {
    width: 100%;
    display: inline-block;
}
.footer-row {
    margin-bottom: 4px;
}

.footer-row.emphasis {
    font-weight: 500;
}

.footer-row.mb-extra {
    margin-bottom: 16px;
}

.footer-details {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    
}
.footer-icon {
    display: inline-block;
    width: 2rem;
}
@media screen and (min-width: 992px) {
    .footer-col.contact-details {
        margin-bottom: 0;
    }
}
@media screen and (min-width: 1400px) {
    .footer-details {
        margin-left: 0;
    }
}
.map-wrapper {
    overflow: hidden;
    width: fit-content;
}
img.map-img {
    width: 100%;
    display: inline-block;
}
@media screen and (min-width: 768px) {
    .footer-wrapper {
        padding-left: 4rem;
        padding-right: 4rem;
    }
    .footer-content {
        width: auto;
    }
    .footer-wrapper .footer-1 {
        width: 50%;
    }
    .footer-wrapper .footer-2 {
        width: 50%;
    }
    .map-wrapper {
        width: auto;
        height: 280px;
        border: #444 1px solid;
        border-radius: 4px;
    }
    img.map-img {
        width: 120%;
        height: auto;
        margin-top: 0;
        margin-left: -10%;
    }
}
@media screen and (min-width: 768px) {
    .footer-content {
        max-width: 720px;
    }
}
@media screen and (max-width: 768px) {
    .footer-content {
        max-width: 540px;
    }
    .footer-wrapper .footer-1 {
        margin-bottom:16px;
    }
}
@media screen and (min-width: 992px) {
    .footer-content {
        max-width: 960px;
    }
    .footer-wrapper .footer-1 {
        width: 50%;
    }
    .footer-wrapper .footer-2 {
        width: 50%;
    }
    img.map-img {
        width: 110%;
        height: auto;
        margin-top: -15%;
        margin-left: -5%;
    }
}
@media screen and (min-width: 1200px) {
    .footer-content {
        max-width: 1140px;
    }
    .footer-wrapper .footer-1 {
        width: 60%;
    }
    .footer-wrapper .footer-2 {
        width: 40%;
    }
}
@media screen and (min-width: 1400px) {
    .footer-content {
        max-width: 1320px;
    }
    .footer-wrapper .footer-1 {
        width: 70%;
    }
    .footer-wrapper .footer-2 {
        width: 30%;
    }
    img.map-img {
        width: 110%;
        height: auto;
        margin-top: -10%;
        margin-left: -10%;
    }
}

#message0 > .main-text {
    padding-bottom: 4rem;
}
.message0-title {
    margin-bottom: 2rem;
    font-size: 2rem !important;
}
.message0-title::before {
    content: "";
    position: absolute;
    left: 49.6%;
    bottom: 10px;
    width: 378px;
    height: 14px;
    transform: skew(-12deg) translateX(-50%);
    background: #ff8101dd;
    z-index: -1;
}

img.full-width {
    width: 100%;
}
#message0 .link-text {
    margin-top: 1rem;
    text-align: center;
}
.message0-p {
    display: block;
    margin-bottom: 1rem;
    text-align: left;
}

#message1 > .main-text {
    padding-bottom: 4rem;
}
.message1-title {
    margin-bottom: .5rem;
    font-size: 2rem !important;
}
.message1-title::before {
    content: "";
    position: absolute;
    left: 49.6%;
    bottom: 10px;
    width: 314px;
    height: 14px;
    transform: skew(-12deg) translateX(-50%);
    background: #ff8101dd;
    z-index: -1;
}

#message1 .link-text span {
    margin-top: 1rem;
    text-align: center;
}
.message1-p {
    display: block;
    margin-bottom: 1.2rem;
    text-align: center;
    font-size: 1.4rem;
    font-weight: 700;
}
.link-p a {
    display: block;
    margin-bottom: 0;
    text-align: center;
    font-size: 1.3rem;
    color: #ff8101dd;
    text-decoration: none;
}
.link-p a:hover {
    color: #be5f00dd;
    text-decoration: underline;
}

.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}
@media screen and (min-width: 768px) {
    .image-25-percent {
        max-width: 25%;
    }
}
@media screen and (max-width: 768px) {
    .image-25-percent {
        max-width: 100%;
    }
}

.image-25-percent img {
    max-width: 100%;
}
div .h-gutter {
    margin-bottom: .5rem;
}
div .h-gutter-xl {
    margin-bottom: 2.5rem;
}
.mini-subheading {
    text-align: center;
    font-size: 1.3rem;
    color: #555;
    font-weight: 700;
    margin-bottom: 1.2rem;
}

.strong {
    font-weight: 700;
}